body {
    margin:10px 5px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  text-align:left;
}


.background {
	background-image: url(loginbg3.png);
	background-size: cover;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}


.Login {
	background-color: white;
	padding: 30px;
	border-radius: 5px;
	opacity: 0.8; /* 添加opacity属性 */
	transform: translateX(50%) translateX(35%) translateY(10%); /* 添加transform属性 */
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3); /* 添加box-shadow属性 */
	height: 250px; /* 添加height属性 */
}

.Login h2 {
	text-align: center;
	margin-bottom: 20px;
}

.Login label {
	display: block;
	margin-bottom: 10px;
}

.Login input[type="text"],
.Login input[type="password"] {
	padding: 10px;
	margin-bottom: 20px;
	border-radius: 5px;
	border: none;
	width: 100%;
}

.Login input[type="submit"] {
	background-color: #4CAF50;
	color: white;
	padding: 10px;
	border-radius: 5px;
	border: none;
	cursor: pointer;
}

.signup-link {
	margin-top: 20px;
	text-align: center;
}

.signup-link a {
	color: blue;
	text-decoration: underline;
}
